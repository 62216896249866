import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOrders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/orders', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrder(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/orders/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/orders', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/orders-approve', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    separateSevkData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/orders-sevk-separate', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSevkData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/orders-sevk-update', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendSevk(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/orders-sevk-send', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSevkData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/orders-sevk-list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/orders/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/orders/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
