<template>
  <div v-if="data">
    <b-modal
      id="modal-edit"
      ref="modal-edit"
      title="Düzenle"
      hide-footer
    >
      <!-- Form -->
      <b-form
        v-if="selectedSevkData"
        class="p-2"
      >

        <b-form-group
          :label="$t('Fatura Adresi')"
          label-for="customer_address_KDR"
        >
          <b-form-textarea
            id="customer_address_KDR"
            v-model="selectedSevkData.customer_address"
            rows="3"
            :readonly="true"
            disabled
          />

        </b-form-group>
        <hr>
        <b-form-group
          id="sevk_address"
          :label="$t('Sevk Adresi')"
          label-for="sevk_address_KDR"
          :state="sevkAddressState"
          aria-describedby="input-live-help input-live-feedback"
        >
          <b-form-input
            id="sevk_address_KDR"
            v-model="selectedSevkData.sevk_address"
          />

        </b-form-group>
        <b-form-invalid-feedback id="input-live-feedback">
          En fazla 49 karakter girilebilir
        </b-form-invalid-feedback>
        <b-form-group
          id="sevk_address2"
          :label="$t('Sevk Adresi Devamı')"
          label-for="sevk_address_KDR"
          aria-describedby="input-live-help input-live-feedback2"
          :state="sevkAddress2State"
        >
          <b-form-input
            id="sevk_address_KDR"
            v-model="selectedSevkData.sevk_address2"
          />

        </b-form-group>
        <b-form-invalid-feedback id="input-live-feedback2">
          En fazla 49 karakter girilebilir
        </b-form-invalid-feedback>
        <b-form-group
          id="sevk_address3"
          :state="sevkAddress3State"
          :label="$t('Sevk Adresi Devamı')"
          aria-describedby="input-live-help input-live-feedback3"
          label-for="sevk_address_KDR"
        >
          <b-form-input
            id="sevk_address_KDR"
            v-model="selectedSevkData.sevk_address3"
          />

        </b-form-group>
        <b-form-invalid-feedback id="input-live-feedback3">
          En fazla 49 karakter girilebilir
        </b-form-invalid-feedback>
        <b-form-group

          :label="$t('Tarih')"
          label-for="complete_date"
        >
          <b-form-datepicker
            id="complete_date"
            v-model="selectedSevkData.complete_date"
            class="mb-1"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Açıklama')"
          label-for="description_KDR"
        >
          <b-form-textarea
            id="description_KDR"
            v-model="selectedSevkData.description"
            rows="3"
          />

        </b-form-group>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="saveEdit()"
          >
            {{ $t('Kaydet') }}
          </b-button>
        </div>

      </b-form>
    </b-modal>
    <b-modal
      id="modal-separate"
      ref="modal-separate"
      title="Ürün Parçala"
      hide-footer
    >
      <!-- Form -->
      <b-form
        v-if="selectedSevkProduct"
        class="p-2"
      >

        <b-form-group
          :label="$t('Ayrılacak Miktar')"
          label-for="piece_KDR"
        >
          <b-input-group :append="selectedSevkProduct.unit_name">
            <b-form-input
              id="piece_KDR"
              v-model="selectedSevkProduct.piece"
              type="number"
            />
          </b-input-group>

        </b-form-group>
        <b-form-group
          :label="$t('Depo')"
          label-for="store_KDR"
        >
          <v-select
            id="store_KDR"
            v-model="selectedSevkProduct.store_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="selectedSevkProduct.product_stocks"
            :reduce="val => val.store_id"
            :clearable="false"
            label="store_name"
            input-id="m_warning_type"
          >
            <template #option="{ store_name, stock }">

              <span> {{ store_name }} ({{ stock }})</span>
            </template>
            <template #selected-option="{store_name, stock }">

              <span> {{ store_name }} ({{ stock }})</span>
            </template>
          </v-select>

        </b-form-group>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="saveSeparate()"
          >
            {{ $t('Kaydet') }}
          </b-button>
        </div>

      </b-form>
    </b-modal>
    <b-row>
      <!-- blogs -->
      <b-col cols="12">
        <b-card

          :title="'#'+data.order_number+' | '+data.customer_name"
        >

          <!-- eslint-disable vue/no-v-html -->

          <!-- eslint-enable -->
          <hr class="my-2">
          <b-table
            small
            :fields="sevkTable.fields"
            :items="sevkList"
            responsive="sm"
          >

            <!-- A custom formatted column -->
            <template #cell(products)="data">
              <b-overlay
                :show="data.item.showOverlay"
                rounded="sm"
              >
                <b-list-group>
                  <b-list-group-item
                    v-for="(item, index) in data.item.products"
                    :key="index"
                  >

                    <span>{{ item.product_name }} | {{ item.piece }}  {{ item.unit_name }}  x {{ item.unit_price }} {{ item.currency_code }}</span>
                    <b-button
                      v-if="$can('read','m_order_edit') && item.is_completed!=1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="primary"
                      @click="separateProduct(item)"
                    ><feather-icon
                       icon="CopyIcon"
                       class="mr-50"
                     />
                      <span class="align-middle"> {{ $t('Parçala') }}</span>
                    </b-button>
                  </b-list-group-item>

                </b-list-group>
              </b-overlay>
            </template>
            <template #cell(actions)="data">
              <b-overlay
                v-if="$can('read','m_order_edit')"
                :show="data.item.showOverlay"
                rounded="sm"
              >
                <b-button
                  v-if="data.item.is_completed==0"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  style="margin-right:1.5rem;"
                  @click="handleEdit(data.item)"
                >
                  {{ $t('Düzenle') }}
                </b-button>
                <b-badge
                  v-if="data.item.is_completed==1"
                  variant="success"
                  pill
                  class="badge-round"
                  style="margin-right:1.5rem;"
                >
                  {{ $t('Sevk Edildi') }}
                </b-badge>
                <b-button
                  v-else
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-warning"
                  style="margin-right:1.5rem;"

                  @click="sendSevk(data.item)"
                >
                  <feather-icon
                    icon="SendIcon"
                    class="mr-50"
                  />
                  {{ $t('Depoya Gönder') }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-secondary"
                  style="margin-right:1.5rem;"
                  @click="downloadSevkCsv(data.item)"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  {{ $t('Csv Olarak İndir') }}
                </b-button>
              </b-overlay>
            </template>
            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              {{ data.value }}
            </template>

          </b-table>
        </b-card>
      </b-col>
      <!--/ blogs -->

      <!-- Leave a Blog Comment -->

      <!--/ Leave a Blog Comment -->
    </b-row></div>
</template>

<script>
import {

  BCard, BRow, BCol, BTable, BListGroup, BListGroupItem, BButton, BForm, BFormGroup, BFormInput, BInputGroup, BModal, BFormTextarea, BFormDatepicker, BBadge, BOverlay, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BRow,
    BInputGroup,
    BButton,
    BTable,
    BOverlay,
    BFormInvalidFeedback,
    BFormInput,
    BFormDatepicker,
    BForm,
    BFormTextarea,
    vSelect,
    BBadge,
    BFormGroup,
    BCol,
    BListGroup,
    BListGroupItem,
    BModal,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedSevkProduct: null,
      selectedSevkData: null,
      sevkTable: {
        fields: [
          { key: 'store_name', label: 'Depo' },
          // A regular column
          { key: 'products', label: 'Ürünler' },
          // A virtual column made up from two fields
          { key: 'actions', label: '' },
        ],

      },
      sevkList: null,
      search_query: '',
      commentCheckmark: '',
      blogDetail: [],
      blogSidebar: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
    }
  },
  computed: {
    sevkAddressState() {
      if (this.selectedSevkData != null) {
        if (this.selectedSevkData.sevk_address != null) { return this.selectedSevkData.sevk_address.length <= 49 }
      }
      return true
    },
    sevkAddress2State() {
      if (this.selectedSevkData != null) {
        if (this.selectedSevkData.sevk_address2 != null) { return this.selectedSevkData.sevk_address2.length <= 49 }
      } return true
    },
    sevkAddress3State() {
      if (this.selectedSevkData != null) {
        if (this.selectedSevkData.sevk_address3 != null) { return this.selectedSevkData.sevk_address3.length <= 49 }
      } return true
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    downloadSevkCsv(sevkData) {
      const sendIds = []
      sevkData.products.forEach(val => {
        sendIds.push(val.sevk_data_id)
      })

      const excelUrl = `${process.env.VUE_APP_API_URL}/api/orders-sevk-csv?send_ids=${sendIds.join()}&token=${useJwt.getToken()}`
      window.location.href = `${excelUrl}`
    },
    sendSevk(sevkData) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Ürünü sevk ettiğinizde sipariş hazırlanmak üzere depoya gönderilecek'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Onaylıyorum'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // eslint-disable-next-line no-param-reassign
          sevkData.showOverlay = true
          const sendIds = []
          sevkData.products.forEach(val => {
            sendIds.push(val.sevk_data_id)
          })
          store.dispatch('app-order/sendSevk', { send_ids: sendIds.join() })
            .then(() => {
              this.$bvToast.toast(this.$t('Ürünler depoya sevk edildi'), {
                title: this.$t('İşlem Başarılı'),
                variant: 'success',
                solid: false,
              })
              // eslint-disable-next-line no-param-reassign
              sevkData.showOverlay = false
              this.fetchData()
            })
            .catch(() => {
              // eslint-disable-next-line no-param-reassign
              sevkData.showOverlay = false
              this.showErrorMessage()
            })
        }
      })
    },
    separateProduct(productObj) {
      this.selectedSevkProduct = JSON.parse(JSON.stringify(productObj))
      this.selectedSevkProduct.piece = 0

      this.$refs['modal-separate'].show()
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    saveSeparate() {
      if (this.selectedSevkProduct.piece <= 0) {
        this.$bvToast.toast(this.$t('Ürün mikgarı sıfırdan büyük olmalı'), {
          title: this.$t('Dikkat'),
          variant: 'danger',
          solid: false,
        })
        return
      }

      store.dispatch('app-order/separateSevkData', { sevk_data_id: this.selectedSevkProduct.sevk_data_id, piece: this.selectedSevkProduct.piece, store_id: this.selectedSevkProduct.store_id })
        .then(() => {
          this.$refs['modal-separate'].hide()
          this.fetchData()
          this.showSuccessMessage()
        })
    },
    handleEdit(dataObj) {
      this.selectedSevkData = dataObj
      this.$refs['modal-edit'].show()
    },
    saveEdit() {
      const saveIds = []
      this.selectedSevkData.products.forEach(val => {
        saveIds.push(val.sevk_data_id)
      })
      store.dispatch('app-order/updateSevkData', {
        sevk_address: this.selectedSevkData.sevk_address, sevk_address2: this.selectedSevkData.sevk_address2, sevk_address3: this.selectedSevkData.sevk_address3, complete_date: this.selectedSevkData.complete_date, description: this.selectedSevkData.description, sevk_ids: saveIds,
      })
        .then(() => {
          this.$refs['modal-edit'].hide()
          this.fetchData()
          this.showSuccessMessage()
        })
    },
    fetchData() {
      store.dispatch('app-order/fetchSevkData', { order_id: this.data.id })
        .then(response => {
          const dataArr = response.data.data.sevk_data_list
          dataArr.forEach(val => {
            // eslint-disable-next-line no-param-reassign
            val.showOverlay = false
          })
          this.sevkList = dataArr
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
